import { CategoryType, FormatType, Finishes, CardType, GameType } from 'types/deck'
import { DeckCardInfoType } from 'types/search'

import { CardResponse } from 'services/apiTypes/card.types'
import { Owner } from 'services/apiTypes/comment.types'
import { DeckTagRelation } from './deckTag.types'

export type Action = 'remove' | 'modify' | 'add'

export type DeckRequest = {
  name: string
  copyId: number | undefined
  deckFormat: FormatType | null
  edhBracket: number | null
  private: boolean
  unlisted: boolean
  theorycrafted: boolean
  game: null | GameType
  description: string
  featured: string
  customFeatured: string
  playmat: string
  parentFolder: number | null
  cardPackage: null | CardPackageInfo
  playgroupDeckUrl?: string
  extras?: {
    decksToInclude?: number[]
    commandersToAdd?: number[]
    ignoreDuplicateCards?: boolean
    forceCardsToSingleton?: boolean
    ignoreCardsOutOfCommanderIdentity?: boolean
    forceCardsToMaybeboard?: boolean
  }
}

export type EstimatedBudget = null | 0 | 1 | 2 | 3 | 4 // 0 Refers to any value (null), 1-4 refer to the budget level

export type CardPackageInfo = {
  id?: number
  keywords: string
  intendedFormats: FormatType[]
  budget: EstimatedBudget
  importCount?: number
}

export type DeckQueryParams = {
  userid?: string
  size?: string | number
  gtesieze?: string | number
  ltesize?: string | number
  owner?: string
  ownerexact?: string
  recentId?: string
  name?: string
  orderBy?: string
  followed?: string
  pageSize?: string | number
  page?: string | number
  formats?: FormatType | FormatType[]
  cards?: string | string[]
  andcards?: string | string[]
  commanders?: string | string[]
  colors?: string | string[]
  andcolors?: string | string[]
  ids?: string | string[]
}

export type DeckListResponse = {
  count: number
  next: string
  results: DeckCardInfoType[]
}

export const defaultDeckListResponse = { count: 0, next: '', results: new Array(15).fill(undefined) }

export type Cateogry = {
  id: number
  name: string
  includedInDeck: boolean
  includedInPrice: boolean
  isPremier: boolean
}

export type DeckCard = {
  card: CardResponse
  quantity: number
  modifier: Finishes
  category: string // depricated
  label: string
  categories: string[]
  id: string | number // deck relation id
}

export type DeckResponse = {
  id: number
  owner: {
    id: number
    username: string
    avatar: string
    frame: string | null
    ckAffiliate: string
    tcgAffiliate: string
    referrerEnum: string | null
  }
  cards: DeckCard[]
  commentRoot: number
  points: number
  userInput: number
  categories: Cateogry[]
  name: string
  description: string
  viewCount: number
  createdAt: string
  updatedAt: string
  featured: string
  customFeatured: string
  playmat: string
  size: number
  private: boolean
  unlisted: boolean
  theorycrafted: boolean
  deckFormat: FormatType
  edhBracket: number | null
  folder: any
  parentFolder: number
  game: any
  upvotes: any[]
  downvotes: any[]
  colors: number[]
  viewers: any[]
  snapshotMeta?: {
    description: string
    parentDeckId: number
    parentDeckName: string
  }
  editors: number[]
  bookmarked: boolean
  deckTags: DeckTagRelation[]
  cardPackage: CardPackageInfo | null
  playgroupDeckUrl: string | null
}

export type DeckModifyResponse = {
  success: boolean
}

export type DeckWithCardType = {
  name: string
  id: number
  hasCard: 0 | 1 | 2 // O - not in deck, 1 - same name in deck, 2 - exact in deck
}
export type DecksWithCardResponseType = {
  decks: DeckWithCardType[]
}

export type DeckVoteResponseType = {
  points: number
}

export type SynchronizeCategoriesResponseType = {
  categories: CategoryType[]
}

export type Log = {
  id: number
  card: CardType
  deck: number
  createdAt: string
  updatedAt: string
  updatedBy: Owner
  action: Action
  data: Changes
}

export type Changes = {
  oldCardId?: string | number
  categories?: string[]
  modifier?: string
  quantity?: number
  customCmc?: number | null
  companion?: boolean
  label?: string
}

export type Snapshot = {
  id: string | number
  name: string
  description: string
  createdAt: string
  updatedAt: string
}
